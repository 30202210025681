<template>
   <v-main class="d-flex justify-space-center pt-1 mb-16" style="position:relative; overflow:auto">
      <v-container >
        <v-row>
          <v-col cols='12'>
            <v-card-title >
              1. Нормативные документы, регламентирующие требования ПБ
            </v-card-title>
          </v-col>

          <v-col cols="12">
            <v-stepper
              v-model="e6"
              vertical
            >
              <v-stepper-step
                :complete="e6 > 1"
                step="1"
                color="red"
              >
                Постановление Правительства РФ от 16.09.2020г. № 1479 "Об утверждении Правил противопожарного режима"
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card
                  color="grey lighten-4"
                  class="mb-12"
                  height="200px"
                >
                  <v-card-text class="pb-0">
                    <p class="subtitle-1 text--primary font-weight-bold">
                      Постановление Правительства РФ "Об утверждении Правил противопожарного режима":
                    </p>
                    <p class="body-1">Устанавливает требования ПБ, определяющие порядок проведения людей, порядок организации производства и (или) содержания территорий, зданий, сооружений, помещений организации и других объектов защиты в целях обеспечения ПБ</p>
                    <v-btn tag="a" href="/doc/ntd/PPR_Postanovlenie_N1479.pdf" download="Постановление Правительства РФ от 16.09.2020г. № 1479 Об утверждении Правил противопожарного режима.pdf">Скачать документ</v-btn>
                  </v-card-text>
                </v-card>
                <v-btn color="red" dark @click="e6 = 2">
                  Далее
                </v-btn>
              </v-stepper-content>
              <v-stepper-step
                color="red"
                :complete="e6 > 2"
                step="2"
              >
                Федеральный закон от 21.12.1994 № 69-ФЗ "О пожарной безопасности"
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card
                  color="grey lighten-4"
                  class="mb-12"
                  height="250px"
                >
                <v-card-text class="pb-0">
                  <p class="subtitle-1 text--primary font-weight-bold">
                    ФЗ "О пожарной безопасности":
                  </p>
                  <p class="body-1">Определяет общие правовые, экономические и социальные основы обеспечения ПБ в РФ;</p>
                  <p class="body-1">Регулирует отношения между органами государственной власти, органами местного самоуправления, общественными объединениями, юридическими лицами, должностными лицами, гражданами (физ. лицами), в том числе индивидуальными предпринимателями.</p>
                  <v-btn tag="a" href="/doc/ntd/FZ_69.pdf" download="Федеральный закон от 21.12.1994 № 69-ФЗ О пожарной безопасности.pdf">Скачать документ</v-btn>
                </v-card-text>
                </v-card>
                <v-btn
                  color="red"
                  @click="e6 = 3"
                  dark
                >
                  Далее
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                color="red"
                :complete="e6 > 3"
                step="3"
              >
                 Федеральный закон от 06.05.2011 № 100-ФЗ "О добровольной пожарной охране"
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-card
                  color="grey lighten-4"
                  class="mb-12"
                  height="250px"
                >
                <v-card-text class="pb-0">
                  <p class="subtitle-1 text--primary font-weight-bold">
                    ФЗ "О добровольной пожарной охране":
                  </p>
                  <p class="body-1">Устанавливает правовые основы создания и деятельности добровольной пожарной охраны. Права и гарантии деятельности общественных объединений пожарной охраны и доровольных пожарных;</p>
                  <p class="body-1">Регулирует отношения добровольной пожарной охраны с органами государственной власти, местного самоуправления, организациями и гражданами РФ, инстранными гражданами и лицами без гражданства.</p>
                  <v-btn tag="a" href="/doc/ntd/FZ_100.pdf" download="Федеральный закон от 06.05.2011 № 100-ФЗ О добровольной пожарной охране.pdf">Скачать документ</v-btn>
                </v-card-text>
                </v-card>
                <v-btn
                  color="red"
                  @click="e6 = 4"
                  dark
                >
                  Далее
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                color="red"
                :complete="e6 > 4"
                step="4"
              >
                <p style="line-height: 1.5!important;">Свод правил СП 4.13130.2013 "Системы противопожарной защиты. Ограничение распространения пожара на объектах защиты. Требования к объемно-планировочным и конструктивным решениям"</p>
              </v-stepper-step>

              <v-stepper-content step="4">
                <v-card
                  color="grey lighten-4"
                  class="mb-12"
                  height="250px"
                >
                <v-card-text class="pb-0">
                  <p class="subtitle-1 text--primary font-weight-bold">
                    ФЗ "О добровольной пожарной охране":
                  </p>
                  <p class="body-1">Устанавливает правовые основы создания и деятельности добровольной пожарной охраны. Права и гарантии деятельности общественных объединений пожарной охраны и доровольных пожарных;</p>
                  <p class="body-1">Регулирует отношения добровольной пожарной охраны с органами государственной власти, местного самоуправления, организациями и гражданами РФ, инстранными гражданами и лицами без гражданства.</p>
                  <v-btn tag="a" href="/doc/ntd/SP_4.13130.2013.pdf" download="Свод правил СП 4.13130.2013 Системы противопожарной защиты.pdf">Скачать документ</v-btn>
                </v-card-text>
                </v-card>
                <v-btn
                  color="red"
                  @click="e6 = 5"
                  dark
                >
                  Далее
                </v-btn>
              </v-stepper-content>

              <v-stepper-step color="red" step="5">
                Федеральный закон от 22.07.2008 № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"
              </v-stepper-step>
              <v-stepper-content step="5">
                <v-card
                  color="grey lighten-4"
                  class="mb-12"
                  height="250px"
                >
                <v-card-text class="pb-0">
                  <p class="subtitle-1 text--primary font-weight-bold">
                    ФЗ "Технический регламент о требованиях пожарной безопасности":
                  </p>
                  <p class="body-1">Определяет основные положения технического регулирования в области ПБ;</p>
                  <p class="body-1">Устанавливает общие требования ПБ к объектам защиты (продукции), в том числе к зданиям и сооружениям, производственным объектам, пожарно-технической продукции и продукции общего назначения.</p>
                  <v-btn tag="a" href="/doc/ntd/FZ_123.pdf" download="Федеральный закон от 22.07.2008 № 123-ФЗ Технический регламент о требованиях пожарной безопасности.pdf">Скачать документ</v-btn>
                </v-card-text>
                </v-card>
                <v-btn
                  color="red"
                  @click="e6 = 1"
                  dark
                >
                  Далее
                </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-col>
          <Hint></Hint>
        </v-row>
      </v-container>
  </v-main>
</template>
<script>
import { mapGetters } from 'vuex'
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint
  },
  computed: {
    ...mapGetters([
      'allCards'
    ])
  },
  data: () => ({
    e6: 1,
    reveal1: false,
    reveal2: false,
    reveal3: false,
    reveal4: false,
    reveal5: false,
    drawer: null,
    selectedItem: 0,
    items: [
      { text: '<span>1. Нормативные документы,<br> регламентирующие требования ПБ</span>', icon: 'mdi-flag' },
      { text: '<span>2. Инструкция о мерах пожарной <br> безопасности</span>', icon: 'mdi-flag' },
      { text: '<span>1. Нормативные документы,<br> регламентирующие требования ПБ</span>', icon: 'mdi-flag' }
    ]
  }),
  methods: {
    setProgressPage: function () {
      localStorage.page2 = this.$route.meta.progress
    }
  }
}
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

</style>
